import { useBreakpoints, breakpointsBootstrapV5 } from "@vueuse/core";

export default () => {
  const breakpoints = useBreakpoints(breakpointsBootstrapV5);

  return {
    isMobile: breakpoints.isSmaller("lg"),
    isDesktop: breakpoints.isGreater("lg"),
  };
};
