import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "form-input" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["inputmode", "id", "type", "autofocus", "disabled", "value", "maxlength", "integral", "number", "egative"]
const _hoisted_4 = {
  key: 0,
  class: "form-input__hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'form-input__inner',
        _ctx.active && 'form-input__inner--active',
        _ctx.disabled && 'form-input__inner--disabled',
        _ctx.error && 'form-input__inner--error',
      ]),
      style: _normalizeStyle({ borderColor: _ctx.borderColor })
    }, [
      _createElementVNode("label", {
        class: _normalizeClass([
          'form-input__label',
          (_ctx.active || _ctx.modelValue) && 'form-input__label--active',
          _ctx.disabled && 'form-input__label--disabled',
          _ctx.error && 'form-input__label--error',
        ]),
        for: _ctx.id,
        style: _normalizeStyle([
          !_ctx.disabled && { backgroundColor: _ctx.bgColor },
          !_ctx.bgColor && { backgroundColor: '#fff' },
          _ctx.modelValue && _ctx.modelValue.length && !_ctx.active && { color: _ctx.color },
        ])
      }, _toDisplayString(_ctx.label), 15, _hoisted_2),
      _createElementVNode("input", {
        class: "form-input__html",
        ref: "inputRef",
        style: _normalizeStyle([
          _ctx.appendLabel && 'padding-left: 50px',
          (_ctx.float || _ctx.fontEnglish) && { fontFamily: 'IRANSansX' },
          !_ctx.disabled && { backgroundColor: _ctx.bgColor },
        ]),
        inputmode: 
          _ctx.priceFormat || _ctx.type === 'tel' || _ctx.type === 'number' || _ctx.numeric
            ? 'decimal'
            : 'text'
        ,
        id: _ctx.id,
        type: _ctx.type,
        autofocus: _ctx.autofocus,
        disabled: _ctx.disabled || _ctx.readonly,
        value: _ctx.formatedValue,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onFocusout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.active = false)),
        onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.active = true)),
        onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onKeydown && _ctx.onKeydown(...args))),
        maxlength: _ctx.maxlength,
        integral: (_ctx.type === 'tel' || _ctx.type === 'number' || _ctx.numeric) && true,
        number: (_ctx.type === 'tel' || _ctx.type === 'number' || _ctx.numeric) && true,
        egative: (_ctx.type === 'tel' || _ctx.type === 'number' || _ctx.numeric) && true
      }, null, 44, _hoisted_3),
      (_ctx.appendLabel)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([
          'form-input__append-label',
          _ctx.readonly && 'form-input__append-label--readonly',
        ])
          }, _toDisplayString(_ctx.appendLabel), 3))
        : _createCommentVNode("", true)
    ], 6),
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeIn",
      "leave-active-class": "animate__animated animate__fadeOut",
      class: "animate__faster",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.hint && !_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.hint), 1))
          : (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(['form-input__hint', _ctx.error && 'form-input__hint--error'])
              }, _toDisplayString(_ctx.errorMessage), 3))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}