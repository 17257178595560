
import { defineComponent, getCurrentInstance, ref, computed } from "vue";
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { decimalCount } from "@/utils";

const Types = ["text", "number", "email", "password", "tel"];

export default defineComponent({
  name: "FormInput",
  props: {
    modelValue: {
      required: false,
      type: [String, Number],
      default: null,
    },
    type: {
      required: false,
      type: String,
      validator: (val: string) => Types.includes(val),
      default: Types[0],
    },
    label: {
      required: true,
      type: String,
    },
    appendLabel: {
      required: false,
      type: String,
      default: null,
    },
    autofocus: Boolean,
    disabled: Boolean,
    error: Boolean,
    readonly: Boolean,
    priceFormat: Boolean,
    dateFormat: Boolean,
    float: Boolean,
    numeric: Boolean,
    fontEnglish: Boolean,
    errorMessage: {
      required: false,
      type: String,
      default: null,
    },
    hint: {
      required: false,
      type: String,
      default: null,
    },
    maxlength: {
      required: false,
      type: Number,
      default: null,
    },
    bgColor: {
      required: false,
      type: String,
      default: "#fff",
    },
    borderColor: {
      required: false,
      type: String,
      default: "#e0e0e0",
    },
    color: {
      required: false,
      type: String,
      default: "#afb7bd",
    },
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const active = ref<boolean>(props.disabled ? false : props.autofocus);
    const inputRef = ref<HTMLInputElement>();

    if (props.readonly) {
      active.value = false;
    }

    const formatedValue = computed(() => {
      if (props.priceFormat && props.modelValue) {
        if (props.float) {
          // if integer
          if (
            parseFloat(String(props.modelValue).replaceAll(",", "")) ===
            parseInt(String(props.modelValue).replaceAll(",", ""))
          ) {
            return Number(props.modelValue).toLocaleString();
          } else {
            // if decimal
            const formated = parseFloat(String(props.modelValue))
              .toFixed(6)
              .replace(/(\.0+|0+)$/, "");

            return (
              parseFloat(
                String(props.modelValue).split(".")[0]
              ).toLocaleString() +
              "." +
              formated.split(".")[1]
            );
          }
        } else {
          return props.modelValue
            ? Number(props.modelValue).toLocaleString("en-US")
            : props.modelValue;
        }
      }
      return props.modelValue;
    });

    const onInput = (e: InputEvent) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let value: string = e.target.value;

      if (props.priceFormat) {
        value = digitsFaToEn(value);

        if (props.float) {
          // if integer
          if (
            parseFloat(value.replaceAll(",", "")) ===
            parseInt(value.replaceAll(",", ""))
          ) {
            emit("update:modelValue", Number(value.replaceAll(",", "")));
          } else {
            // if decimal
            // if more than 2 dots
            if (value.split(".").length - 1 > 1) {
              const formated = parseFloat(
                value.slice(0, value.lastIndexOf(".")).replaceAll(",", "")
              );
              emit("update:modelValue", formated);
            } else {
              if (isNaN(Number(value.replaceAll(",", "")))) {
                emit("update:modelValue", "");
              } else {
                const formated =
                  Number(
                    String(value).split(".")[0].replaceAll(",", "")
                  ).toLocaleString() +
                  "." +
                  Number(value.replaceAll(",", ""))
                    .toFixed(6)
                    .split(".")[1]
                    .replace(/(\.0+|0+)$/, "");

                emit(
                  "update:modelValue",
                  parseFloat(formated.replaceAll(",", ""))
                );
              }
            }
          }
        } else {
          const reg = new RegExp(/^\d*(\.\d+)?$/);

          if (!reg.test(value.replaceAll(",", ""))) e.preventDefault();
          emit("update:modelValue", value.replace(/[^0-9]/gi, ""));
        }
      } else {
        emit("update:modelValue", value);
      }
    };

    const onKeydown = (e: KeyboardEvent) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const key = e.key;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const value = e.target.value;

      const keys = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "۰",
        "۱",
        "۲",
        "۳",
        "۴",
        "۵",
        "۶",
        "۷",
        "۸",
        "۹",
        "Backspace",
        "ArrowRight",
        "ArrowLeft",
        ".",
      ];

      if (props.float) {
        const selected = document.getSelection();

        if (
          selected?.toString() &&
          props.modelValue &&
          decimalCount(value) > 5
        ) {
          e.preventDefault();
          emit("update:modelValue", e.key);
        }

        if (decimalCount(value) > 5 && key !== "Backspace") {
          e.preventDefault();
        }
      }

      if (props.priceFormat) {
        !keys.includes(key) && e.preventDefault();
      }
    };

    return {
      active,
      id: `input-${instance?.uid}`,
      formatedValue,
      onInput,
      onKeydown,
      inputRef,
    };
  },
});
