import { watch } from "vue";
import { useTitle } from "@vueuse/core";
import useConfig from "./useConfig";

export default function useMetaTitle(newTitle: string) {
  const { site_name } = useConfig();
  const title = useTitle();

  watch(
    () => site_name.value,
    (value) => {
      title.value = `${value} - ${newTitle}`;
    },
    {
      immediate: true,
    }
  );
}
