
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "FormButton",
  props: {
    loading: Boolean,
    disabled: Boolean,
    borderedDisabled: Boolean,
    fullWidth: Boolean,
    label: {
      required: true,
      type: String,
    },
    type: {
      required: false,
      type: String as PropType<"submit" | "button">,
      default: "button",
    },
  },
});
